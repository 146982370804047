type NotificationBadgeProps = {
  count?: number | boolean;
};

export const NotificationBadge = ({ count }: NotificationBadgeProps) =>
  count ? (
    <div className="absolute -right-2 -top-2 items-center justify-center rounded-full bg-red-600 px-1.5 py-0 text-xs font-medium text-white">
      <small>{count}</small>
    </div>
  ) : (
    <div className="absolute -right-2 -top-2 size-4 items-center justify-center rounded-full bg-red-600 text-xs font-medium text-white" />
  );
