type StickyBannerProps = {
  message: string;
  children?: React.ReactNode;
  onCloseLinkTo?: string;
};

export const StickyBanner = ({
  message,
  children,
  onCloseLinkTo,
}: StickyBannerProps) => {
  return (
    <div className="absolute left-1/2 top-1 z-10 w-auto -translate-x-1/2 items-center rounded-full bg-yellow-200 py-0.5 pl-6 pr-4 text-sm font-semibold text-yellow-800 transition">
      <div className="group flex flex-row items-center gap-2">
        <div className="flex grow flex-col items-center">{message}</div>
        {children}
        {onCloseLinkTo && (
          <div className="flex shrink-0">
            <a
              href={onCloseLinkTo}
              className="p-0.5 text-lg opacity-50 transition group-hover:opacity-100"
            >
              &times;
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
