import { Badge, BadgeColor } from '@/ui/Badge';

const colors: Record<string, BadgeColor> = {
  active: BadgeColor.Green,
  inactive: BadgeColor.Red,
  paused: BadgeColor.Yellow,
  onboarding: BadgeColor.Blue,
  waiting: BadgeColor.Yellow,
};

export const StatusBadge = ({ status }: { status: string }) => {
  return <Badge color={colors[status]}>{status}</Badge>;
};
