import { UserRole } from '@/types/core/UserRole';

export const roleTitle = (role: UserRole): string => {
  switch (role) {
    case UserRole.Admin:
    case UserRole.Superadmin:
      return 'Administrator';
    case UserRole.MD:
      return 'Physician';
    case UserRole.RD:
      return 'Dietitian';
    case UserRole.PA:
      return 'Physician Assistant';
    case UserRole.CareCoordinator:
      return 'Care Coordinator';
    case UserRole.Advisor:
      return 'Advisor';
    case UserRole.NP:
      return 'Nurse Practitioner';
    case UserRole.MedicalAssistant:
      return 'Medical Assistant';
    case UserRole.PharmacyTechnician:
      return 'Pharmacy Tech';
  }

  console.error(new Error(`Unknown role: ${role}`));
};
