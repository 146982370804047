import { WeeklyPeriod } from '@/types/core/AvailabilityRule';

export const getTotalPeriodMinutes = (periods: WeeklyPeriod[]) =>
  periods.reduce((total, period) => {
    if (period.startTime && period.endTime) {
      const start = new Date(`2023-09-01T${period.startTime}`);
      const end = new Date(`2023-09-01T${period.endTime}`);
      return total + (end.getTime() - start.getTime()) / 1000 / 60;
    }
    return total;
  }, 0);
