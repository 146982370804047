export const PrescriberRole = {
  MD: 'md',
  PA: 'pa',
  NP: 'np',
} as const;
export type PrescriberRole =
  (typeof PrescriberRole)[keyof typeof PrescriberRole];

export const ProviderRole = {
  ...PrescriberRole,
  RD: 'rd',
} as const;
export type ProviderRole = (typeof ProviderRole)[keyof typeof ProviderRole];
export const PrescriberRoles = Object.values(PrescriberRole);

export const AdminRole = {
  Admin: 'admin',
  Superadmin: 'superadmin',
} as const;
export type AdminRole = (typeof AdminRole)[keyof typeof AdminRole];

export const UserRole = {
  ...ProviderRole,
  ...AdminRole,
  Advisor: 'advisor',
  CareCoordinator: 'pcc',
  MedicalAssistant: 'ma',
  PharmacyTechnician: 'pharma',
} as const;
export type UserRole = (typeof UserRole)[keyof typeof UserRole];
