// Rotate the given angle 90 degress clockwise.
//
// Uploadcare's rotate parameter is counter-clockwise, so we need to rotate
// the angle 270 degress counter-clockwise in order to rotate it 90 degrees
// clockwise.
//
// @param {number} angle - The angle to rotate.
// @returns {number} - The rotated angle.
export const rotateClockwise = (angle?: number) => {
  return (angle || 360) - 90;
};
