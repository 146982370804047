import clsx from 'clsx';

import { Image } from '@/ui/Image';

type AvatarProps = {
  url?: string;
  initials?: string;
  name?: string;
  size?: 'base' | 'sm';
};

export const Avatar = ({ url, initials, name, size = 'base' }: AvatarProps) => {
  return (
    <div
      className={clsx(
        'relative inline-flex shrink-0 items-center justify-center overflow-hidden rounded-full border-2 border-white bg-gray-400 ring-1 ring-gray-300 transition hover:scale-110 hover:shadow-md focus:outline-none',
        {
          'h-10 w-10': size === 'base',
          'h-8 w-8': size === 'sm',
        },
      )}
      title={name || initials}
    >
      {url ? (
        <Image alt={name || initials || ''} src={url} />
      ) : (
        <span
          className={clsx('font-medium leading-none text-white', {
            'text-sm': size === 'base',
            'text-xs': size === 'sm',
          })}
        >
          {initials}
        </span>
      )}
    </div>
  );
};
