import { addDays, startOfWeek } from 'date-fns';

import { formatDateISO } from './formatDateISO';

export const weekOf = (date: Date) => {
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  return Array.from({ length: 7 }).map((_, i) =>
    formatDateISO(addDays(startDate, i)),
  );
};
