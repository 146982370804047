type FormatDateOptions = {
  timeZone?: string;
  format?: 'long' | 'medium' | 'short';
};

export const formatDate = (
  date: Date,
  { timeZone, format = 'long' }: FormatDateOptions = {},
) =>
  date.toLocaleDateString('en-US', {
    timeZone,
    weekday: format === 'long' ? 'long' : 'short',
    year: format === 'long' || format === 'medium' ? 'numeric' : undefined,
    month: format === 'long' ? 'long' : 'short',
    day: 'numeric',
  });
