import { Menu, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import {
  BellIcon,
  BookOpenIcon,
  Cog8ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { Fragment, useState } from 'react';

import { useNotifications } from '@/lib/queries/useNotifications';
import { isAdmin, isPrescriber } from '@/lib/utils';
import type { User } from '@/types/core/User';
import { Avatar } from '@/ui/Avatar';
import { NotificationBadge } from '@/ui/NotificationBadge';

import { NotificationsPanel } from '../NotificationsPanel';
import { SearchModal } from './SearchModal';

const PriorAuthIcon = () => (
  <div className="flex size-4 items-center justify-center text-2xl">℞</div>
);

type MenuLinkProps = {
  href: string;
  children: React.ReactNode;
};

type SideNavProps = {
  user?: User;
};

const MenuLink = ({ href, children, ...props }: MenuLinkProps) => (
  <Link
    href={href}
    {...props}
    role="menuitem"
    className="mx-1 block rounded-md p-3 text-lg font-medium transition hover:bg-gray-200 md:text-sm"
  >
    {children}
  </Link>
);

export const SideNav = ({ user }: SideNavProps) => {
  const { data: notifications } = useNotifications();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isNotificationsPanelOpen, setIsNotificationsPanelOpen] =
    useState(false);

  const navigation = [
    { name: 'Home', href: '/dashboard', icon: HomeIcon },
    {
      name: 'Inbox',
      href: '/inbox',
      icon: InboxIcon,
      count: notifications ? notifications.tasks : 0,
    },
    {
      name: 'Notifications',
      onClick: () => setIsNotificationsPanelOpen(true),
      icon: BellIcon,
      count: notifications ? notifications.notifications.length : 0,
    },
    user?.role &&
      isPrescriber(user.role) && {
        name: 'Prior Authorizations',
        href: '/prior_authorizations',
        icon: PriorAuthIcon,
      },
    {
      name: 'Library',
      href: '/library',
      icon: BookOpenIcon,
    },
    user?.superadmin && {
      name: 'Advisor Registration',
      href: '/advisor/registrations/new',
      icon: UserPlusIcon,
    },
    {
      name: 'Search',
      testid: 'SideNav-search',
      icon: MagnifyingGlassIcon,
      onClick: () => setIsSearchOpen(true),
    },
    user &&
      isAdmin(user) && {
        name: 'Admin',
        href: '/admin',
        icon: Cog8ToothIcon,
      },
    {
      name: 'Help',
      onClick: () => {
        window.Beacon
          ? window.Beacon('toggle')
          : window.alert(
              'NEXT_PUBLIC_HELPSCOUT_BEACON_ID has not been configured',
            );
      },
      icon: QuestionMarkCircleIcon,
    },
  ].filter(Boolean);

  return (
    <div className="z-30 flex h-12 w-full shrink-0 bg-gray-800 md:h-full md:w-12 2xl:w-16 print:hidden">
      <div className="mx-auto flex w-full flex-row md:h-full md:flex-col">
        <Menu as="div" className="relative z-40 ml-2 shrink-0 md:mx-auto">
          <div>
            <Menu.Button className="mt-1 flex w-full shrink-0 rounded-full p-0 text-sm text-white focus:outline-none md:mt-5">
              <span className="sr-only">Open user menu</span>
              <div>
                <Avatar
                  initials={user?.initials}
                  url={user?.photoThumbnailUrl}
                  name={user?.name}
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute -left-2 flex w-screen origin-top-left flex-col bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none md:left-2 md:mt-2 md:w-48 md:rounded-md">
              <Menu.Item>
                <MenuLink href="/settings/connected_accounts">
                  Connected Accounts
                </MenuLink>
              </Menu.Item>
              <Menu.Item>
                <MenuLink href="/settings/availability">Availability</MenuLink>
              </Menu.Item>
              <Menu.Item>
                <MenuLink href="/sign_out">Sign Out</MenuLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <div className="mx-2 flex min-h-0 w-full flex-row overflow-y-auto md:mx-auto md:flex-col">
          <nav
            aria-label="Sidebar"
            className="flex w-full flex-row items-center justify-around gap-1 md:my-3 md:flex-col"
          >
            {navigation.map(
              (item) =>
                item &&
                (item.onClick ? (
                  <button
                    key={item.name}
                    onClick={item.onClick}
                    className="flex items-center rounded-lg p-2 text-indigo-200 hover:bg-indigo-700 2xl:p-4"
                  >
                    <div className="relative">
                      <item.icon className="size-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                      {item.count && item.count > 0 ? (
                        <NotificationBadge
                          count={item.count < 10 && item.count}
                        />
                      ) : null}
                    </div>
                  </button>
                ) : (
                  <Link
                    key={item.name}
                    href={item.href}
                    title={item.name}
                    onClick={item.onClick}
                    data-testid={item.testid}
                    className="flex items-center rounded-lg p-2 text-indigo-200 hover:bg-indigo-700 2xl:p-4"
                  >
                    <div className="relative">
                      <item.icon className="size-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                      {item.count && item.count > 0 ? (
                        <NotificationBadge
                          count={item.count < 10 && item.count}
                        />
                      ) : null}
                    </div>
                  </Link>
                )),
            )}
          </nav>
        </div>

        {user && (
          <SearchModal
            isOpen={isSearchOpen}
            onClose={() => setIsSearchOpen(false)}
          />
        )}

        <NotificationsPanel
          isOpen={isNotificationsPanelOpen}
          onClose={() => setIsNotificationsPanelOpen(false)}
        />
      </div>
    </div>
  );
};
