import Router from 'next/router';
import { useEffect, useState } from 'react';

import { useSession } from '@/lib/queries/useSession';

export function useAuth({ redirectTo = '/auth/' } = {}) {
  const { isError, isLoading, isSuccess, data: session } = useSession();
  const user = session?.user;

  const [wasSignedIn, setWasSignedIn] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setWasSignedIn(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!redirectTo || isLoading) return;
    if (redirectTo && isError && !wasSignedIn) {
      Router.push({
        pathname: redirectTo,
        query: { returnTo: Router.asPath },
      });
    }
  }, [wasSignedIn, isError, isLoading, redirectTo]);

  return { session, user };
}
