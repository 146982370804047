import { Program } from '@/lib/api/generated/api.schemas';

type WithPrograms =
  | {
      programs: Program[];
    }
  | undefined;

export function getProgramSetting<T>(
  patient: WithPrograms,
  key: string,
): T | undefined {
  if (!patient) {
    return undefined;
  }
  return patient.programs.map((p) => p.settings[key] as T).find(Boolean);
}
