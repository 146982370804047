import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

export const relativeAge = (date: Date | string) => {
  if (!date) {
    return null;
  }

  const now = new Date();
  const then = new Date(date);
  const ageDays = differenceInDays(now, then);

  if (ageDays > 0) {
    return `${ageDays}d`;
  } else {
    const ageHours = differenceInHours(now, then);
    if (ageHours > 0) {
      return `${ageHours}h`;
    } else {
      const ageMinutes = differenceInMinutes(now, then);
      return `${ageMinutes}m`;
    }
  }
};
