import { useMutation, useQueryClient } from '@tanstack/react-query';

import { dismissNotifications } from '../api/core/dismissNotifications';

export const useDismissNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: dismissNotifications,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });
};
