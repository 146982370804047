import axios from 'axios';

import { CoreAxiosError } from '@/types/core/errors/CoreAxiosError';

export function errorMessage(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  } else if (Array.isArray(error)) {
    return error.flatMap(errorMessage).join(', ');
  } else if (axios.isAxiosError(error)) {
    return errorMessage((error as CoreAxiosError).response?.data?.errors);
  } else if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error === 'object') {
    return errorMessage(Object.values(error));
  } else {
    console.error(error);
    return 'Something went wrong, please try again';
  }
}
