import { addDays, differenceInDays } from 'date-fns';

import { UnavailablePeriod } from '@/types/core/UnavailablePeriod';

import { isAllDay } from '../time/isAllDay';

const buildDailyPeriod = ({
  period,
  offset,
}: {
  period: UnavailablePeriod;
  offset: number;
}) => {
  const startsAt = addDays(new Date(period.startsAt), offset);
  const endsAt = addDays(startsAt, 1);

  return {
    id: `${period.id}-${offset}`,
    startsAt: startsAt.toISOString(),
    endsAt: endsAt.toISOString(),
    userId: period.userId,
    reason: period.reason,
  };
};

type Params = {
  period: UnavailablePeriod;
  timeZone: string;
};

export const splitUnavailablePeriod = ({ period, timeZone }: Params) => {
  if (isAllDay(period, timeZone)) {
    const days = differenceInDays(
      new Date(period.endsAt),
      new Date(period.startsAt),
    );

    return days == 1
      ? [period]
      : Array.from({ length: days }, (_, offset) =>
          buildDailyPeriod({ period, offset }),
        );
  } else {
    return [period];
  }
};
