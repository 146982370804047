const PHONE_TEST = new RegExp(
  /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/,
);

// https://stackoverflow.com/a/41318684
// "It accepts almost all imaginable ways of writing a US phone number.
// The result is formatted to a standard form of (987) 654-3210 x123"
export const formatUsPhone = (phone?: string) => {
  if (phone) {
    phone = phone.trim();
    const results = PHONE_TEST.exec(phone);
    if (results !== null && results.length > 8) {
      return `(${results[3]}) ${results[4]}-${results[5]}`;
    }
  }

  return phone;
};
