export const parseJsonSafely = (json?: string | null) => {
  try {
    return json && json.length > 0 && JSON.parse(json);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return null;
    }
    throw e;
  }
};
