import { timeOfDay } from './timeOfDay';

// Converts a time of day in HH:MM format or minutes since midnight to hh:MM AM/PM format.
export const formatTimeOfDay = (tod: string | number) => {
  const t = typeof tod === 'number' ? timeOfDay(tod) : tod;

  const [hour, minute] = t.split(':');
  const hourInt = Number(hour);
  const suffix = hourInt >= 12 ? 'PM' : 'AM';
  const twelveHour = hourInt > 12 ? hourInt - 12 : hourInt;
  const formattedHour = (twelveHour || 12).toString().padStart(2, '0');

  return `${formattedHour}:${minute} ${suffix}`;
};
