import { isMidnight } from './isMidnight';

export type IsoDateRange = {
  startsAt: string;
  endsAt: string;
};

export const isAllDay = (
  { startsAt, endsAt }: IsoDateRange,
  timeZone: string,
) =>
  startsAt !== endsAt &&
  isMidnight(startsAt, timeZone) &&
  isMidnight(endsAt, timeZone);
