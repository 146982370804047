import { Dialog, Transition } from '@headlessui/react';
import { BellSlashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import Router from 'next/router';
import { Fragment, useEffect, useMemo } from 'react';

import { useNotifications } from '@/lib/queries/useNotifications';
import { UserNotificationEvent } from '@/types/core/UserNotification';
import { fonts } from '@/ui/fonts';

import { NotificationGroup } from '../Notifications/NotificationGroup';

const ZeroState = () => (
  <div className="mx-auto mt-12 flex flex-col gap-10">
    <div className="mx-auto">
      <div className="rounded-full bg-white/50 p-6 text-center">
        <BellSlashIcon className="size-20 text-gray-800" />
      </div>
    </div>
    <p className="text-center font-semibold text-white/50">
      No notifications at this time
    </p>
  </div>
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationsPanel = ({ isOpen, onClose }: Props) => {
  const { data, isSuccess } = useNotifications();
  const notifications = data?.notifications;

  useEffect(() => {
    Router.events.on('routeChangeComplete', onClose);
    return () => {
      Router.events.off('routeChangeComplete', onClose);
    };
  }, [onClose]);

  const groupedNotifications = useMemo(
    () => (isSuccess ? groupBy(notifications, (n) => n.event) : null),
    [isSuccess, notifications],
  );

  const groups = useMemo(() => {
    if (groupedNotifications) {
      const keys = Object.keys(groupedNotifications) as UserNotificationEvent[];
      return sortBy(
        keys.map((event) => ({
          event,
          count: groupedNotifications[event].length,
        })),
        'count',
      ).reverse();
    }
  }, [groupedNotifications]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`${fonts.className} relative z-10`}
        onClose={onClose}
      >
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full md:pl-12 2xl:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-96">
                  <div className="flex h-full flex-col overflow-y-scroll bg-gray-800 shadow-xl">
                    <div className="flex flex-col gap-4 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-xl font-semibold leading-6 text-white">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3">
                        {isSuccess &&
                          groupedNotifications &&
                          groups?.map((group) => (
                            <NotificationGroup
                              key={group.event}
                              event={group.event}
                              notifications={groupedNotifications[group.event]}
                            />
                          ))}

                        {isSuccess && !notifications?.length && <ZeroState />}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
