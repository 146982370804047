import { Inter } from 'next/font/google';

const inter = Inter({
  variable: '--font-inter',
  display: 'swap',
  subsets: ['latin'],
});

const fonts = {
  className: `${inter.variable} font-sans`,
};

export { fonts };
