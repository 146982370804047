import { useQuery } from '@tanstack/react-query';

import { fetchUserNotifications } from '@/lib/api/core/fetchUserNotifications';

import { isRetryable } from '../utils/error/isRetryable';

export const useNotifications = () =>
  useQuery({
    queryKey: ['notifications'],
    queryFn: fetchUserNotifications,
    retry: (failureCount, error) => isRetryable(error) && failureCount <= 3,
  });
