import { XMarkIcon } from '@heroicons/react/20/solid';
import { isSameDay } from 'date-fns';
import Link from 'next/link';

import { useDismissNotifications } from '@/lib/queries/useDismissNotifications';
import { formatTimestamp } from '@/lib/utils';
import { UserNotification } from '@/types/core/UserNotification';
import { Spinner } from '@/ui/Spinner';

type Props = {
  notification: UserNotification;
};

export const Notification = ({ notification }: Props) => {
  const createdAt = new Date(notification.createdAt);
  const today = new Date();
  const formatStr = isSameDay(createdAt, today) ? 'hh:mm a' : 'MMM dd';
  const dismissNotifications = useDismissNotifications();

  return (
    <div className="rounded-lg bg-green-50 p-5">
      <div className="flex">
        <div className="flex grow flex-col gap-1">
          {notification?.url ? (
            <Link href={notification.url}>
              <div className="font-semibold">{notification.title}</div>
              <div className="text-sm">{notification.description}</div>
            </Link>
          ) : (
            <div>
              <div className="font-semibold">{notification.title}</div>
              <div className="text-sm">{notification.description}</div>
            </div>
          )}
        </div>
        <div className="flex shrink-0 flex-col items-end">
          <button
            onClick={() => dismissNotifications.mutate([notification.id])}
            disabled={dismissNotifications.isPending}
            className="h-6"
          >
            {dismissNotifications.isPending ? (
              <Spinner className="size-4 text-gray-400" />
            ) : (
              <XMarkIcon className="size-6 text-gray-400" />
            )}
          </button>
          <div
            className="text-sm text-gray-400"
            title={formatTimestamp(createdAt)}
          >
            {formatTimestamp(createdAt, { formatStr })}
          </div>
        </div>
      </div>
    </div>
  );
};
