import { useRouter } from 'next/router';
import { useState } from 'react';

import { PatientSearch } from '@/ui/PatientSearch';

import { Modal } from '../Modal';

type SearchModalProps = {
  isOpen: boolean;
  onClose: () => void;
  lg?: boolean;
};

export const SearchModal = ({ isOpen, onClose, lg }: SearchModalProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const router = useRouter();

  const onSelect = (selected?: { value: string }) => {
    if (selected) {
      setIsSelected(true);
      router.push(`/patients/${selected.value}`);
    }
  };

  return (
    <Modal isOpen={isOpen && !isSelected} onClose={onClose} lg={lg}>
      <div className="flex md:text-sm">
        <PatientSearch onSelect={onSelect} />
      </div>
    </Modal>
  );
};
