import axios from 'axios';

const coreHost = process.env.NEXT_PUBLIC_CORE_API_HOST || '';
export const coreApiUrl = (path: string) =>
  `${coreHost}/api/dashboard/${path}` as const;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const getCore = async <T>(
  path: string,
  params?: Record<string, unknown>,
) => {
  const { data } = await axios.get<T>(coreApiUrl(path), {
    params,
    headers,
    withCredentials: true,
  });
  return data;
};

export const postCore = async <T>(
  path: string,
  body?: Record<string, unknown>,
) => {
  const { data } = await axios.post<T>(coreApiUrl(path), body || {}, {
    headers,
    withCredentials: true,
  });
  return data;
};

export const deleteCore = async <T>(
  path: string,
  body?: Record<string, unknown>,
) => {
  const { data } = await axios.delete<T>(coreApiUrl(path), {
    headers,
    withCredentials: true,
    data: body,
  });
  return data;
};

export const patchCore = async <T>(
  path: string,
  body?: Record<string, unknown>,
) => {
  const { data } = await axios.patch<T>(coreApiUrl(path), body || {}, {
    headers,
    withCredentials: true,
  });
  return data;
};
