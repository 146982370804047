import { addDays } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

import { formatDateISO } from '@/lib/utils/time/formatDateISO';
import { localDate } from '@/lib/utils/time/localDate';
import { weekOf } from '@/lib/utils/time/weekOf';
import { AvailabilityRule, WeeklyPeriod } from '@/types/core/AvailabilityRule';
import { AvailablePeriod } from '@/types/core/AvailablePeriod';
import { UnavailablePeriod } from '@/types/core/UnavailablePeriod';

const getEffectiveRules = (
  availabilityRules: AvailabilityRule[],
  from: string,
  to: string,
) =>
  availabilityRules.filter(
    (rule) =>
      (!rule.startOn || rule.startOn <= to) &&
      (!rule.endOn || rule.endOn > from),
  );

const DAY_OF_WEEK_KEYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const buildRecurringPeriod = ({
  period,
  timeZone,
  date,
}: {
  period: WeeklyPeriod;
  timeZone: string;
  date: string;
}) => {
  return {
    id: `${date}-${period.startTime}`,
    startAt: fromZonedTime(
      `${date}T${period.startTime}:00`,
      timeZone,
    ).toISOString(),
    endAt: fromZonedTime(
      `${date}T${period.endTime}:00`,
      timeZone,
    ).toISOString(),
  };
};

const isRuleEffectiveOn = (rule: AvailabilityRule, date: string) =>
  (!rule.startOn || rule.startOn <= date) && (!rule.endOn || rule.endOn > date);

const getRecurringPeriods = (
  availabilityRules: AvailabilityRule[],
  weekStartOn: string,
) =>
  availabilityRules.flatMap((rule) =>
    rule.weeklyPeriods.reduce((memo: AvailablePeriod[], period) => {
      const date = formatDateISO(
        addDays(localDate(weekStartOn), DAY_OF_WEEK_KEYS.indexOf(period.day)),
      );

      if (isRuleEffectiveOn(rule, date)) {
        memo.push(
          buildRecurringPeriod({ period, timeZone: rule.timeZone, date }),
        );
      }
      return memo;
    }, []),
  );

const getDiffMinutes = ({ from, to }: { from: string; to: string }) => {
  const endsAt = new Date(to);
  const startsAt = new Date(from);
  return Math.floor((endsAt.getTime() - startsAt.getTime()) / 1000 / 60);
};

const getAvailableMinutes = (periods: AvailablePeriod[]) =>
  periods.reduce(
    (total, period) =>
      total + getDiffMinutes({ from: period.startAt, to: period.endAt }),
    0,
  );

const getBlockedMinutes = (
  unavailablePeriod: UnavailablePeriod,
  availablePeriods: AvailablePeriod[],
) => {
  let blockedMinutes = 0;
  availablePeriods.forEach((period) => {
    if (
      period.startAt < unavailablePeriod.endsAt &&
      period.endAt > unavailablePeriod.startsAt
    ) {
      const start =
        period.startAt < unavailablePeriod.startsAt
          ? unavailablePeriod.startsAt
          : period.startAt;
      const end =
        period.endAt > unavailablePeriod.endsAt
          ? unavailablePeriod.endsAt
          : period.endAt;

      blockedMinutes += getDiffMinutes({ from: start, to: end });
    }
  });
  return blockedMinutes;
};

const getUnavailableMinutes = (
  unavailablePeriods: UnavailablePeriod[],
  availablePeriods: AvailablePeriod[],
) =>
  unavailablePeriods.reduce(
    (total, unavailablePeriod) =>
      total + getBlockedMinutes(unavailablePeriod, availablePeriods),
    0,
  );

type Params = {
  date: string;
  availablePeriods: AvailablePeriod[];
  availabilityRules: AvailabilityRule[];
  unavailablePeriods: UnavailablePeriod[];
};

export const getWeekAvailability = ({
  date,
  availablePeriods,
  availabilityRules,
  unavailablePeriods,
}: Params) => {
  const dates = weekOf(localDate(date));
  const effectiveRules = getEffectiveRules(
    availabilityRules,
    dates[0],
    dates[dates.length - 1],
  );
  const recurringPeriods = getRecurringPeriods(effectiveRules, dates[0]);
  const allAvailablePeriods = [...recurringPeriods, ...availablePeriods];
  const availableMinutes = getAvailableMinutes(allAvailablePeriods);
  const unavailableMinutes = getUnavailableMinutes(
    unavailablePeriods,
    allAvailablePeriods,
  );

  return Math.max(availableMinutes - unavailableMinutes, 0);
};
