import { format, formatInTimeZone } from 'date-fns-tz';

type FormatTimestampOptions = {
  timeZone?: string;
  formatStr?: string;
};

export const formatTimestamp = (
  t?: string | Date | null,
  options?: FormatTimestampOptions,
): string | undefined => {
  if (!t) return undefined;

  const timeZone = options?.timeZone;
  const formatStr = options?.formatStr || 'E PP p z';

  if (timeZone) {
    return formatInTimeZone(new Date(t), timeZone, formatStr);
  } else {
    return format(new Date(t), formatStr);
  }
};
