'use client';
import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;
const configDefaults = {
  ignoreNetworkEvents: false,
  propagateTraceHeaderCorsUrls: [/core(-staging)?\.formhealth\.io/g],
};
export default function Observability() {
  try {
    const sdk = new HoneycombWebSDK({
      debug:
        process.env.NEXT_PUBLIC_HONEYCOMB_DEBUG !== 'false' &&
        (DEPLOY_ENV ? DEPLOY_ENV === 'development' : false),
      apiKey: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY,
      serviceName: 'mantle',
      instrumentations: [
        getWebAutoInstrumentations({
          '@opentelemetry/instrumentation-xml-http-request': configDefaults,
          '@opentelemetry/instrumentation-fetch': configDefaults,
          '@opentelemetry/instrumentation-document-load': configDefaults,
          '@opentelemetry/instrumentation-user-interaction': {
            enabled: false,
          },
        }),
      ],
    });
    sdk.start();
  } catch (e) {
    return null;
  }
  return null;
}
