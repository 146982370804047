import { useRouter } from 'next/router';

export const SessionExpiry = ({ expired = false }: { expired: boolean }) => {
  const router = useRouter();

  return (
    <>
      {expired && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-black/25 backdrop-blur transition-opacity"
              aria-hidden="true"
            />

            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl backdrop-blur-0 transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle">
              <div className="mt-3 flex flex-row">
                <div className="flex items-start text-6xl">🔒</div>
                <div className="ml-5">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Your session expired.
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Sessions expire due to inactivity. Please refresh to sign
                      in again.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  onClick={() => router.reload()}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
