export const localizeTime = (
  utc: string | Date,
  options: Intl.DateTimeFormatOptions,
) => {
  return new Date(utc).toLocaleTimeString('en-US', {
    timeZoneName: 'short',
    hour: 'numeric',
    minute: '2-digit',
    ...options,
  });
};
