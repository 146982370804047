import { useCallback, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type ImageProps = {
  alt: string;
  src: string;
  height?: number | string;
  width?: number | string;
  className?: string;
  eagerLoad?: boolean;
  hideBrokenImage?: boolean;
  onClick?: () => void;
  onError?: () => void;
};

type BrokenImageProps = {
  height?: number | string;
  width?: number | string;
};

const BrokenImage = ({ height, width }: BrokenImageProps) => (
  <div
    className="flex size-full items-center justify-center bg-gray-100"
    style={{ height, width }}
  >
    <button
      className="size-full text-xs font-medium uppercase text-gray-400"
      disabled
    >
      No image
    </button>
  </div>
);

export const Image = ({
  eagerLoad,
  alt,
  hideBrokenImage,
  onClick,
  onError,
  ...props
}: ImageProps) => {
  const [isBroken, setIsBroken] = useState(false);

  const handleError = useCallback(() => {
    setIsBroken(true);
    onError && onError();
  }, [onError]);

  return isBroken && hideBrokenImage ? (
    <BrokenImage {...props} />
  ) : (
    <LazyLoadImage
      {...props}
      alt={alt}
      onClick={onClick}
      data-chromatic="ignore"
      onError={handleError}
      visibleByDefault={eagerLoad}
    />
  );
};
