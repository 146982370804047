export const formatLocalDate = (
  date?: number | string | Date,
  options?: Intl.DateTimeFormatOptions,
) =>
  date
    ? new Date(date).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        weekday: 'short',
        ...options,
      })
    : undefined;
