import { OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { postCore } from '@/lib/api/core/client';
import { usePatient } from '@/lib/queries/usePatient';
import { formatBirthdate } from '@/lib/utils';
import { Patient } from '@/types/core/Patient';
import { StatusBadge } from '@/ui/StatusBadge';

type PatientOption = {
  value: string;
  label: string;
  patient: Patient;
};

type PatientSearchProps = {
  onSelect: (option?: PatientOption) => void;
  selected?: string;
  placeholder?: string;
};

const search = async (q: string) => {
  const data = await postCore<Patient[]>('search', { q });
  return data.map((patient) => ({
    value: patient.id,
    label: formatPatientName(patient),
    patient,
  }));
};

const formatPatientName = ({ firstName, legalName, lastName }: Patient) => {
  if (firstName !== legalName) {
    return `${legalName} "${firstName}" ${lastName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
};

type CustomOptionProps = {
  innerProps: OptionProps['innerProps'];
  isDisabled: boolean;
  data: { patient: Patient };
};

const CustomOption = ({
  innerProps,
  isDisabled,
  data: { patient },
}: CustomOptionProps) => {
  return !isDisabled ? (
    <div
      {...innerProps}
      className="w-full cursor-pointer px-2 py-1 transition hover:bg-gray-100"
    >
      <div className="flex flex-row gap-4">
        <div className="flex grow flex-col">
          <div className="font-bold text-gray-700">
            {formatPatientName(patient)}
          </div>
          <div className="font-medium text-gray-400">
            {formatBirthdate(patient.birthdate)}
          </div>
        </div>
        <div className="flex shrink-0">
          <div>
            <StatusBadge status={patient.status} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export const PatientSearch = ({
  onSelect,
  selected,
  ...props
}: PatientSearchProps) => {
  const { data: patient } = usePatient(selected);

  return (
    <AsyncSelect
      id="patient-search"
      isClearable
      loadOptions={search}
      value={
        selected && patient
          ? { value: selected, label: formatPatientName(patient), patient }
          : undefined
      }
      onChange={(newValue) => onSelect(newValue ? { ...newValue } : undefined)}
      placeholder="Search"
      components={{
        Option: CustomOption,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          borderWidth: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }),
        menu: (base) => ({
          ...base,
          width: 'max-content',
          minWidth: '300px',
        }),
      }}
      className="w-full"
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
      })}
      {...props}
    />
  );
};
