// Given a number of minutes since midnight, returns a string representing the
// time of day in HH:MM format.
export const timeOfDay = (minutesSinceMidnight: number) => {
  const hh = Math.floor(minutesSinceMidnight / 60)
    .toString()
    .padStart(2, '0');
  const mm = (minutesSinceMidnight % 60).toString().padStart(2, '0');

  return `${hh}:${mm}`;
};
