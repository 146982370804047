import Script from 'next/script';
import { useEffect } from 'react';

import { useSession } from '@/lib/queries/useSession';

type HelpscoutBeaconProps = {
  beaconId?: string;
};

type BeaconInfo = {
  beaconId: string;
};

export const HelpscoutBeacon = ({
  beaconId = process.env.NEXT_PUBLIC_HELPSCOUT_BEACON_ID,
}: HelpscoutBeaconProps) => {
  const { data: session } = useSession();

  useEffect(() => {
    if (session && beaconId && window.Beacon) {
      const info = window.Beacon('info') as BeaconInfo;

      if (!info || info.beaconId !== beaconId) {
        window.Beacon('init', beaconId);

        window.Beacon('config', {
          enableFabAnimation: true,
          chatEnabled: true,
        });

        window.Beacon('identify', {
          name: session.user.name,
          email: session.user.email,
          jobTitle: session.user.role,
          company: 'Form Health',
          avatar: session.user.photoThumbnailUrl,
          signature: session.helpScoutSignature,
        });
      }
    }
  }, [beaconId, session]);

  if (!beaconId) {
    return null;
  }
  return (
    <Script id="helpscout-beacon">
      {`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}
    </Script>
  );
};
