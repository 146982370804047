import { useQuery } from '@tanstack/react-query';

import { fetchSession } from '@/lib/api/core/fetchSession';

import { isRetryable } from '../utils/error/isRetryable';

export const useSession = () =>
  useQuery({
    queryKey: ['session'],
    queryFn: fetchSession,
    retryOnMount: true,
    retry: (failureCount, error) => isRetryable(error) && failureCount <= 3,
    retryDelay: (failureCount) => Math.min(100 * 2 ** failureCount, 500),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchInterval: 1000 * 60, // 1 minute
  });
