import { setUser } from '@sentry/nextjs';
import { useEffect } from 'react';

import { useSession } from '@/lib/queries/useSession';

export const useSentryIdentification = () => {
  const { isSuccess, data: session } = useSession();

  useEffect(() => {
    if (isSuccess && session?.authenticatedUser) {
      setUser({
        id: session.authenticatedUser.id ?? undefined,
        email: session.authenticatedUser.email ?? undefined,
        username: session.authenticatedUser.name ?? undefined,
      });
    }
  }, [isSuccess, session]);

  return null;
};
